import React, { Fragment } from "react";
import Img from "gatsby-image";
import { graphql, StaticQuery } from "gatsby";
import { Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import * as styles from "./aboutGrid.module.less";

const ABOUTGRID_QUERY = graphql`
  query AboutGrid {
    img0: file(relativePath: { eq: "About/about-featured-100.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "About/about-vancouver.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "About/about-featured-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "About/about-featured-103.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "About/about-featured-104.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "About/about-featured-vancouver.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "Culture/culture-12x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "Culture/culture-22x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "Culture/culture-32x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "Culture/culture-42x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img10: file(relativePath: { eq: "Culture/culture-52x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img11: file(relativePath: { eq: "Culture/culture-62x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const AboutGrid = (props) => {
  const { culture } = props;
  return (
    <Row className={styles.pictureGrid}>
      <StaticQuery
        query={ABOUTGRID_QUERY}
        render={({
          img0,
          img1,
          img2,
          img3,
          img4,
          img5,
          img6,
          img7,
          img8,
          img9,
          img10,
          img11,
        }) => (
          <Fragment>
            {culture ? (
              <>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={0}
                  >
                    <Img
                      fluid={img6?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={100}
                  >
                    <Img
                      fluid={img7?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={200}
                  >
                    <Img
                      fluid={img8?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={300}
                  >
                    <Img
                      fluid={img9?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={400}
                  >
                    <Img
                      fluid={img10?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={500}
                  >
                    <Img
                      fluid={img5?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
              </>
            ) : (
              <>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={0}
                  >
                    <Img
                      fluid={img0?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={100}
                  >
                    <Img
                      fluid={img1?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={200}
                  >
                    <Img
                      fluid={img2?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={300}
                  >
                    <Img
                      fluid={img3?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={400}
                  >
                    <Img
                      fluid={img4?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col className={styles.singleGrid}>
                  <AnimationOnScroll
                    animateOnce
                    animateIn="animate__fadeIn"
                    offset={0}
                    delay={500}
                  >
                    <Img
                      fluid={img5?.childImageSharp?.fluid}
                      className={styles.singleGridImage}
                    />
                  </AnimationOnScroll>
                </Col>
              </>
            )}
          </Fragment>
        )}
      />
    </Row>
  );
};

export default AboutGrid;
