import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Responsibility from "../../assets/About/value-responsibility.svg";
import Excellence from "../../assets/About/value-excellence.svg";
import Integrity from "../../assets/About/value-integrity.svg";
import Innovation from "../../assets/About/value-innovation.svg";
import ValueCard from "./valueCard";
import * as pageStyles from "../../pages/page.module.less";
import * as styles from "./weBelieve.module.css";

const IntegrityCard = {
  img: Integrity,
  title: "Integrity",
  text: "We are transparent and open. We offer trust to our partners and assume it from them.",
};

const ResponsibilityCard = {
  img: Responsibility,
  title: "Responsibility",
  text: "We own the work we do and take responsibility for it, striving to make it the best possible.",
};
const ExcellenceCard = {
  img: Excellence,
  title: "Excellence",
  text: "Every one of our values contributes towards the excellence in our products.",
};
const InnovationCard = {
  img: Innovation,
  title: "Innovation",
  text: "Our products are crafted for the future needs of the industry, going beyond what exists in the market.",
};

const cardArray = [
  IntegrityCard,
  ResponsibilityCard,
  InnovationCard,
  ExcellenceCard,
];

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  @media screen and (max-width: 768px) {
    padding-top: 0;
    margin-bottom: 40px;
    text-align: center;
  }
`;

const Header = styled.h3`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  padding-bottom: 25px;
  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

const Text = styled.span`
  font-size: 16px;
  letter-spacing: 0.03px;
  color: rgba(36, 45, 65, 0.7);
  line-height: 30px;
`;

const WeBelieve = () => {
  return (
    <Row>
      <Col md={4} className={pageStyles.coliPad}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeIn" offset={0}>
          <TextWrapper>
            <Header>How we work</Header>
            <Text>
              Our values describe what we stand for. These four values shape our
              culture, guide the way we work and the way we grow as a company.
            </Text>
          </TextWrapper>
        </AnimationOnScroll>
      </Col>
      <Col md={8} className={pageStyles.coliPad}>
        <Row>
          {cardArray.map((card, key) => (
            <Col md={6} sm={6} className={styles.valueCol}>
              <AnimationOnScroll
                animateOnce
                animateIn="animate__fadeIn"
                offset={0}
                delay={key * 100}
              >
                <ValueCard img={card.img} title={card.title} text={card.text} />
              </AnimationOnScroll>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default WeBelieve;
