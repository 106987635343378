import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import GoArrow from "../../assets/icon-arrow-white.svg";

const GetInTouchBarWrapper = styled.div`
  width: 100%;
  height: 76px;
  background-image: linear-gradient(to left, #03d4ff, #1e5bb5);
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 400px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const GetInTouchText = styled.span`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 13px;
  }
  &:hover {
    img {
      transform: translateX(3px);
      transition: all 0.1s;
    }
  }
`;

const GetInTouchBar = () => {
  return (
    <GetInTouchBarWrapper>
      <Link to="/culture/">
        <GetInTouchText>
          Learn more about our culture <img src={GoArrow} alt="arrow" />
        </GetInTouchText>
      </Link>
    </GetInTouchBarWrapper>
  );
};

export default GetInTouchBar;
