import React from 'react';
import styled from 'styled-components';

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    padding-top: 0;
    text-align: left;
    @media screen and (max-width: 576px) {
        text-align: center;
        align-items: center;
        margin-bottom: 20px;
    }
`

const StyledImage = styled.img`
    height: 90px;
    margin-bottom: 5px;
    @media screen and (max-width: 991px) {
        height: 50px;
    }
`

const Title = styled.span`
    padding: 10px 0;
    font-weight: 600;
    font-size: 16px;
    color: #242D41;
`

const Text = styled.span`
    font-size: 16px;
    color: rgba(36,45,65,0.7);
    @media screen and (max-width: 991px) {
        font-size: 14px;
    }
`

const ValueCard = (props) => (
  <CardWrapper>
    <StyledImage src={props.img} alt={props.title} />
    <Title>
      {props.title}
    </Title>
    <Text>
      {props.text}
    </Text>
 </CardWrapper>
);

export default ValueCard;